import { Container, Stack, Typography } from '@mui/material'
import React from 'react'

function Headline({headline,subtitle,...props}) {
  return (
    <Container sx={{ maxWidth: "xl", py: {xs: 3, sm: 4, md: 5}}}>
    <Stack spacing={1}>
    <Typography textAlign={"center"} sx={{fontWeight: 500, typography: {xs: "h6",sm: "h5", md: "h4"}}}>
           {headline}
        </Typography>
        <Typography color="text.secondary" textAlign={"center"}>
         {subtitle}
        </Typography>
    </Stack>
</Container>
  )
}

export default Headline