import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Alert, Container, Typography } from '@mui/material'
import HeaderTitleBox from '../../components/HeaderTitleBox'

function UnsubscribeContainer() {
  return (
    <>
    <Header></Header>
    <HeaderTitleBox headline="Unsubscribe" imageUrl="https://media.myclubportal.de/Background_Feature_Croped.jpg"/>
    <Container maxWidth="xl" sx={{py: 5, px:2}}>
       
       <Alert severity='success' variant="filled" sx={{alignItems:{xs:"center", md:"center"}}}><Typography variant="h6">Erfolgreich ausgetragen - sie erhalten keine E-Mails mehhr!</Typography></Alert>

    </Container>
    <Footer></Footer>
    </>
  )
}

export default UnsubscribeContainer