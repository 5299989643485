import { Container } from '@mui/system'
import React from 'react'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Headline from '../../components/Headline'
import HeaderTitleBox from '../../components/HeaderTitleBox'

function ContactContainer() {
  return (
    <>
      <Header />
      <HeaderTitleBox headline="Kontakt" imageUrl="https://media.myclubportal.de/Background_Feature_Croped.jpg"/>
      <Headline
            headline="Kontaktiere uns"
            subtitle="Stelle deine spezifische Frage oder fordere einfach ein kostenloses Testsystem an!"
            />
      <Container sx={{ maxWidth: "xl", pb: 5, height: "100%", minHeight: "calc(100vh - 124px)"}}>
        <ContactForm></ContactForm>
      </Container>
      <Footer /></>
  )
}

export default ContactContainer