import {  Grid, Tab, Tabs, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React, { useState } from 'react'
import FAQ from '../../components/FAQ'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import FaqsKonto from '../../mocks/faqs_konto.json'
import FaqsBuchen from '../../mocks/faqs_buchen.json'
import FaqsMCP from '../../mocks/faqs_mcp.json'
import FaqsPunktspiele from '../../mocks/faqs_punktspiele.json'
import FaqsTraining from '../../mocks/faqs_training.json'
import FaqsTeams from '../../mocks/faqs_teams.json'
import { EmojiEventsSharp, FitnessCenterSharp, Group, Person, SportsTennisSharp, Subscriptions } from '@mui/icons-material'
import HeaderTitleBox from '../../components/HeaderTitleBox'

function FAQContainer() {
    const [tabValue, setTabValue] = useState("license");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    //https://media.myclubportal.de/Background_Feature_Croped.jpg
    return (
        <>
            <Header />
            <HeaderTitleBox headline="FAQ" imageUrl="https://media.myclubportal.de/Background_Feature_Croped.jpg" />

            {/* <Headline
                headline="Häufig gestellte Fragen"
                subtitle="Finde in den häufig gestellten Fragen schnell nach einer passenden Antwort zu deinem Anliegen"
            /> */}

            <Container sx={{ maxWidth: "xl", py: 5, height: "100%", minHeight: "calc(100vh - 124px)" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label={<Typography sx={{ textTransform: "none", typography: { xs: "h6", sm: "h4" } }}>Lizenzen</Typography>} value={"license"} />
                                <Tab label={<Typography sx={{ textTransform: "none", typography: { xs: "h6", sm: "h4" } }}>Portal-Funktionen</Typography>} value={"features"} />
                            </Tabs>
                        </Box>
                    </Grid>

                    {tabValue == "license" ?
                        <Grid item>
                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <Subscriptions color="primary"></Subscriptions>
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Beschaffung</Typography>
                            </Stack>

                            {FaqsMCP.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}
                        </Grid>
                        : <></>
                    }
                    {tabValue == "features" ?
                        <Grid item>
                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <Person color="primary" />
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Konto</Typography>
                            </Stack>
                            {FaqsKonto.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}
                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <SportsTennisSharp color="primary"></SportsTennisSharp>
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Buchen</Typography>
                            </Stack>                                {FaqsBuchen.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}

                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <Group color="primary"></Group>
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Mannschaften</Typography>
                            </Stack>                                {FaqsTeams.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}

                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <EmojiEventsSharp color="primary"></EmojiEventsSharp>
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Punktspiele</Typography>
                            </Stack>                                {FaqsPunktspiele.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}

                            <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mb: 4, mt: 4 }}>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                                    <FitnessCenterSharp color="primary"></FitnessCenterSharp>
                                </Box >
                                <Typography variant="h6" textAlign={"center"} color="text.secondary">Training</Typography>
                            </Stack>                                {FaqsTraining.map((faq, i) => (
                                <FAQ key={i} faq={faq}></FAQ>
                            ))}
                        </Grid>
                        :
                        <></>
                    }
                </Grid>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default FAQContainer