import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function InternalLink(props) {
  const { route, linkName,color} = props;

  const navigate = useNavigate();
  const handleClick = (route,external=false) => {
    if(external)
    {
      window.open(route,'_blank').focus();
    }
    else
    {
      navigate(route)
    }

  }
  return (
    <Button variant="text" sx={{ textDecoration: "underline", textTransform: "none", color: color || ""}}  onClick={() => { handleClick(route); }}>{linkName}</Button>
  )
}

export default InternalLink