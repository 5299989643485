export const ROOT = "/";
export const FEATURES = "/features";
export const SUBSCRIPTIONS = "/subscription";
export const CREDITS= "/credits";
export const FAQ="/faq";
export const GDPR="/gdpr"
export const CONTACT="/contact"
export const LOGIN = process.env.REACT_APP_LCM_URL+"/login"
export const UNSUBSCRIBE="/unsubscribe"
export const IMPRINT="/imprint"
