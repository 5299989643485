import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

function BenefitCard({icon,headline,subtext,width="70%"}) {
    return (
        <Stack direction="column" alignItems={"center"} sx={{ mb: 7 }} spacing={1} width={width}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1,borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
                {icon}
            </Box >
            <Typography color="primary" sx={{ fontWeight: "medium", typography: {xs: "h6", md: "h5"} }}>{headline}</Typography>
            <Typography  color="text.secondary" sx= {{typography: {xs: "body2", md: "body1"}} }align='justify'>{subtext}</Typography>
        </Stack>
    )
}

export default BenefitCard