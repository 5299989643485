import { Link, Stack, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

function CreditsContainer() {
  return (
    <>
      <Header></Header>
      <Container sx={{ maxWidth: "xl", py: 5, height: "calc(100vh - 124px)"}}>
        <Stack spacing={1}>
          <Typography variant="h6"> Bildermaterial:</Typography>
          <Link href={"https://www.pexels.com/"} variant="body1" target={"_blank"}>Pexels</Link>
          <Link href={"https://www.freepik.com/free-vector/flat-design-illustration-customer-support_12982910.htm#query=contact&from_query=contatc&position=4&from_view=search&track=sph"} variant="body1" target={"_blank"}>Image by Freepik</Link>
          <Stack>
            Image by <a href="https://www.freepik.com/free-vector/gradient-abstract-wireframe-background_15694142.htm#query=abtract%20backgrounds&position=13&from_view=search&track=ais">Freepik</a>
            Image by <a href="https://www.freepik.com/free-photo/rpa-concept-with-blurry-hand-touching-screen_23992698.htm#query=features&position=4&from_view=search&track=sph">Freepik</a>
          </Stack>
        </Stack>
      </Container>
      <Footer></Footer>
    </>
  )
}

export default CreditsContainer