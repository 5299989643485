import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import ContactContainer from '../pages/contact/ContactContainer';
import CreditsContainer from '../pages/credits/CreditsContainer';
import FAQContainer from '../pages/faq/FAQContainer';
import FeatureContainer from '../pages/features/FeatureContainer';
import GDPRContainer from '../pages/gdpr/GDPRContainer';
import HomeContainer from '../pages/home/HomeContainer';
import SubscriptionContainer from '../pages/subscriptions/SubscriptionContainer';
import { CONTACT, CREDITS, FAQ, FEATURES, GDPR, IMPRINT, ROOT, SUBSCRIPTIONS, UNSUBSCRIBE } from './CONSTANTS';
import NotFound from './NotFound';
import UnsubscribeContainer from '../pages/unsubscribe/UnsubscribeContainer';
import { sendPageHit_api } from '../api/analytics';
import ImprintContainer from '../pages/imprint/ImprintContainer';

function RouterConfig() {

    const router = createBrowserRouter([
        {
            path: ROOT,
            element: <HomeContainer></HomeContainer>,
            errorElement: <NotFound/>
        },
        {
            path: SUBSCRIPTIONS,
            element: <SubscriptionContainer/>
        },
        {
            path: FEATURES,
            element: <FeatureContainer/>
        },
        {
            path: CREDITS,
            element: <CreditsContainer/>
        },
        {
            path: FAQ,
            element: <FAQContainer/>
        },
        {
            path: GDPR,
            element: <GDPRContainer/>
        },
        {
            path: CONTACT,
            element: <ContactContainer/>
        },
        {
            path: UNSUBSCRIBE,
            element: <UnsubscribeContainer/>
        },
        {
            path: IMPRINT,
            element: <ImprintContainer/>
        }

    ]);

    return (
        <RouterProvider router={router}>
        </RouterProvider>
  )
}

export default RouterConfig