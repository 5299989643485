import RouterConfig from "./navigation/RouterConfig";


function App() {
  return (
    <RouterConfig/>
  );
}

export default App;
