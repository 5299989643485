import { Grade, LockSharp, TaskAltSharp } from '@mui/icons-material'
import { Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useState } from 'react'

function SubscritpionCard(props) {
    const { data } = props;
    const [elevation, setElevation] = useState(4);

    const OnMouseOver = () => {
        setElevation(24);
    }

    const OnMouseLeave = () => {
        setElevation(4);
    }

    return (
        <Card elevation={elevation} onMouseOver={() => { OnMouseOver(); }} onMouseLeave={() => { OnMouseLeave(); }}>
            <CardContent sx={{ backgroundColor: "#e9e9e9" }}>
                <Typography variant="h5" align="center" color="text.secondary">{data.title ? data.title : "Einzeltarif"}</Typography>
            </CardContent>
            <CardContent sx={{}}>
                <Grid container direction="row" justifyContent="center" spacing={3}>
                    {data.categories.map((category, i) => (
                        <Grid item xs={12} key={i}>
                            <Stack direction="row" alignItems={"center"} justifyContent="center" spacing={1} mx={2}>
                                <Typography variant="h4" color="text.secondary">{category.price}</Typography>
                                <Typography variant="body1" color="text.secondary" >{category.name}</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>

            <CardContent>
                <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <List dense>
                            {data.featuresEnabled.map((feature, i) => (
                                <ListItem key={i}>
                                    <ListItemIcon><TaskAltSharp color='success'></TaskAltSharp></ListItemIcon>
                                    <ListItemText sx={{ color: "text.secondary" }}>{feature.name}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                        <List dense>
                            {data.featuresDisabled.map((feature, i) => (
                                <ListItem key={i}>
                                    <ListItemIcon><LockSharp color='warning'></LockSharp></ListItemIcon>
                                    <ListItemText sx={{ color: "text.secondary" }}>{feature.name}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {data.additionalInfo.map((info, i) => (
                                <Typography variant="caption" color={"text.secondary"}>{info.text}</Typography>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>

            <CardContent sx={{ backgroundColor: "#e9e9e9" }}>
                <Grid container justifyContent={"center"}>
                    {/* <Button variant={"outlined"} color="secondary">Beratung</Button> */}
                    <Button variant={"contained"} onClick={() => { window.open("mailto:contact@myclubportal.de?subject=MCP " + data.title + " Subscription Request") }}>Anfrage</Button>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SubscritpionCard