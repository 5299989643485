import { Alert, Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContactImage from '../assets/ContactImage.jpg'
import { sendContactRequest_api } from '../api/contact';
import { Send } from '@mui/icons-material';

function ContactForm() {

    const [contactData, setContactData] = useState({ firstname: "", lastname: "", mail: "", message: "", intention: "testsystem" });
    const [status, setStatus] = useState(null);

    const sendContacRequest = () => {
        var promise = sendContactRequest_api(contactData.message, contactData.mail, contactData.firstname, contactData.lastname, contactData.intention);
        promise.then((result) => { setStatus(true) })
            .catch((error) => { setStatus(false) });
    }

    return (
        <>
            <Card elevation={20}>
                <CardContent>
                    <Grid direction="row" alignItems="center" container spacing={1}>
                        {/* <Grid item xs={12}>
                            <Alert severity='warning'><Stack spacing={1}><Typography>Wir bitten um Entschuldigung - aktuell ist die Kontaktaufnahme über das Formular nicht möglich. Bitte wenden Sie sich mit ihrem Anliegen direkt an: </Typography><a href='mailto:contact@myclubportal.de'>contact@myclubportal.de</a></Stack></Alert>
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <Box component="img" src={ContactImage} sx={{ width: "90%" }}></Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid direction="row" container alignItems={"center"} justifyContent="flex-end" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField value={contactData.firstname} fullWidth label="Vorname" onChange={(event) => { setContactData({ ...contactData, firstname: event.target.value }); console.log(event.target.value) }}></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField value={contactData.lastname} fullWidth label="Nachname" onChange={(e) => { setContactData({ ...contactData, lastname: e.target.value }); }}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={contactData.mail} fullWidth label="E-Mail" onChange={(e) => { setContactData({ ...contactData, mail: e.target.value }); }}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Absicht</InputLabel>
                                        <Select value={contactData.intention} label={"Absicht"} fullWidth onChange={(e) => { setContactData({ ...contactData, intention: e.target.value }); }}>
                                            <MenuItem value={"contact"}>Kontaktanfrage</MenuItem>
                                            <MenuItem value={"testsystem"}>Anfrage Testsystem</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={contactData.message} fullWidth label="Nachricht" multiline rows={6} onChange={(e) => { setContactData({ ...contactData, message: e.target.value }); }}></TextField>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" startIcon={<Send></Send>} onClick={() => { sendContacRequest(); }}> Abschicken</Button>
                                </Grid>
                                {status != null ?
                                    <Grid item xs={12}>
                                        {status ?
                                            <Alert variant="filled" severity={"success"}><Typography>Kontaktanfrage wurde erfolgreich verschickt. MyClubPortal wird sich bei Ihnen so schnell wie möglich melden.</Typography></Alert>
                                            :
                                            <Alert variant="filled" severity={"error"}><Typography>Leider ist etwas schief gelaufen...</Typography></Alert>
                                        }
                                    </Grid>
                                    : <></>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default ContactForm