import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Dialog, Stack } from '@mui/material'
import React, { useState } from 'react'

function ImageCaroussel({ pictures }) {
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);

    return (
        <>
            <Stack>
                <Box sx={{ position: "relative" }}>
                    <Box component={"img"} src={pictures[index]} sx={{ width: "100%", borderRadius: 2, objectFit: "cover" }} onClick={() => { setOpen(true); }} />
                    {pictures && pictures.length > 1 && index >= 1 &&
                        <Box sx={{ p: 4, display: "flex", borderRadius: 2, position: "absolute", translate: "0px -50%", top: "50%", left: "2px", backgroundColor: "transparent", height: "fit-content", width: "fit-content" }} onClick={() => { setIndex((index) => { return index - 1 }) }}>
                            <Box sx={{ display: "flex", borderRadius: 2, position: "absolute", translate: "0px -50%", top: "50%", left: "2px", backgroundColor: "rgba(0, 0, 0, .3)", height: "fit-content" }} >
                                <ChevronLeft sx={{ color: "#fff" }} />
                            </Box>
                        </Box>
                    }
                    {pictures && pictures.length > 1 && index < pictures.length - 1 &&
                        <Box sx={{ p: 4, display: "flex", borderRadius: 2, position: "absolute", translate: "0px -50%", top: "50%", right: "2px", backgroundColor: "transparent", height: "fit-content", width: "fit-content" }} onClick={() => { setIndex((index) => { return index + 1 }) }}>
                            <Box sx={{ display: "flex", borderRadius: 2, position: "absolute", translate: "0px -50%", top: "50%", right: "2px", backgroundColor: "rgba(0, 0, 0, .3)", height: "fit-content" }} >
                                <ChevronRight sx={{ color: "#fff" }} />
                            </Box>
                        </Box>
                    }
                </Box>
                {pictures.length > 1 ?
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {pictures && pictures.map((picture, i) => (
                            i<4 &&
                            <Box component={"img"} src={picture} sx={{ width: "calc((100% - 16px) / 4)", borderColor: "text.secondary", filter: index != i ? "brightness(40%)" : "brightness(100%)" }} onClick={() => setIndex(i)} key={i} />
                        ))}
                    </Stack>
                    : <></>
                }
            </Stack>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
                <Box component={"img"} src={pictures[index]} sx={{ width: "100%", borderRadius: 2 }} onClick={() => { }}>
                </Box>
            </Dialog>
        </>

    )
}

export default ImageCaroussel