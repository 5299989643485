import { Card, CardContent, CardHeader, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/Header'
import { ContactPage, LiveHelp, Mail } from '@mui/icons-material'
import SimpleListItem from '../../components/SimpleListItem'
import Footer from '../../components/Footer'

function ImprintContainer() {
    return (
        <>
            <Header/>
            <Container sx={{ maxWidth: "xl", pt: 5, pb: 5, height: "calc(100vh - 124px)"}}>
            <Grid container item justifyContent={"center"} spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Card raised sx={{ boderRadius: 1 }}>
                            <CardHeader title={"Impressum"} />
                            <CardContent>
                                <Grid container rowSpacing={2}>
                                    <Grid item container direction="column" alignItems={"center"}>
                                        <Grid item><Typography variant="h6">{"MyClubPortal"}</Typography></Grid>
                                        <Grid item> <Typography variant="body2">{"Jan-Paul Brückmann"}</Typography></Grid>
                                        <Grid item> <Typography variant="body2">{"Langer Kamp 2, Braunschweig"}</Typography></Grid>
                                        <Grid item> <Typography variant="body2">{"USt-IdNr.: DE367176209"}</Typography></Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid item container direction="column" alignItems={"center"} justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Stack direction="column">
                                                <SimpleListItem icon={<Mail color="primary"></Mail>} object={"contact@myclubportal.de"}></SimpleListItem>
                                                <SimpleListItem icon={<ContactPage color="primary" />} object={"https://myclubportal.de"}></SimpleListItem>
                                                <SimpleListItem icon={<LiveHelp color="primary" />} object={"contact@myclubportal.de"}></SimpleListItem>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    {/* <Grid item container direction="column" alignItems={"center"} xs={12}>
                                        <Typography variant="body2">Der HTC ist im Vereinsregister der Stadt Braunschweig mit der Nr. 3058 eingetragen.</Typography>
                                    </Grid> */}
                                    <Grid item container direction="column" alignItems={"center"}>
                                        <Typography variant="body2">Copyright Jan-Paul Brückmann 2022</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                    </Grid>
            </Container>
            <Footer/>
        </>
    )
}

export default ImprintContainer