import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { CONTACT, FAQ, FEATURES, LOGIN, ROOT, SUBSCRIPTIONS } from '../navigation/CONSTANTS';

function DrawerMenu(props) {
    const { open, setOpen } = props;

    const navigate = useNavigate();

    const handleClick = (route, external = false) => {
        if (external) {
            window.open(route, '_blank').focus();
        }
        else {
            navigate(route)
        }
    }

    return (
        <>
            <Drawer sx={{ display: { xs: "none", sm: "block" } }} anchor={"right"} open={open} onClose={() => { setOpen(false); }}>
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(ROOT); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>Home</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(FEATURES); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>Features</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(SUBSCRIPTIONS); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>Preise</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(CONTACT); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>Kontakt</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(FAQ); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>FAQ</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(LOGIN, true); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6" }}>Login</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Drawer sx={{ display: { xs: "flex", sm: "none" } }} anchor={"bottom"} open={open} onClose={() => { setOpen(false); }}>
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(ROOT); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>Home</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(FEATURES); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>Features</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(SUBSCRIPTIONS); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>Preise</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(CONTACT); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>Kontakt</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(FAQ); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>FAQ</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => { handleClick(LOGIN, true); }}>
                            <ListItemText primaryTypographyProps={{ variant: "h6", textAlign: "center" }}>Login</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}

export default DrawerMenu