import { Devices, Mail, ManageAccounts, StartSharp, VolunteerActivismSharp } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import Feature from '../../components/Feature'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { CONTACT, FEATURES, SUBSCRIPTIONS } from '../../navigation/CONSTANTS'
import { sendPageHit_api } from '../../api/analytics'
import BenefitCard from '../../components/BenefitCard'
import InternalLink from '../../components/InternalLink'
import ImageCaroussel from '../../components/ImageCaroussel'
import { useNavigate } from 'react-router-dom'


function HomeContainer() {

  const [currentImage, setCurrentImage] = useState("https://images.pexels.com/photos/793176/pexels-photo-793176.jpeg");
  const [index, setIndex] = useState(0);
  const [repeater, setRepeater] = useState(0);
  const navigate = useNavigate();



  const externalLink = () => {
    window.open("https://demo.myclubportal.de", "_blank");
  }

  const images = ["https://images.pexels.com/photos/793176/pexels-photo-793176.jpeg",
    "https://images.pexels.com/photos/2961964/pexels-photo-2961964.jpeg",
    "https://images.pexels.com/photos/171568/pexels-photo-171568.jpeg"];

  const features = [
    {
      name: "Das digitale Vereinsportal",
      headline: "",
      description: "Das digitale Vereinsportal ermöglicht ein vollumfängliches Management deines Vereins. Neben der Kernfunktion, online Tennisplätze zu buchen, ist es möglich Plätze, Mannschaften, Punktspiele, Trainings, Veranstaltungen und Turniere zu verwalten. Mithilfe von Umfragen und einem Blog können Mitglieder stets auf dem aktuellen Stand gehalten werden und sogar aktiv ihre Meinung einbringen. MyClubPortal wurde in Zusammenarbeit mit Vereinen und Spielern entwickelt, um den spezifischen Anforderungen der Tennisvereine gerecht zu werden.",
      description2: "Die Club Management Software von MyClubPortal ermöglicht es Clubs und Vereinen, ihre internen Prozesse effektiver zu gestalten und ihre Mitglieder besser zu verwalten. Von der Mitgliederverwaltung, Platzbelegung  bis zur Veranstaltungsplanung, die Software von MyClubPortal ist flexibel und anpassungsfähig, um den spezifischen Anforderungen jedes Clubs gerecht zu werden.",
      picture: ["https://media.myclubportal.de/Devices_croped.png"],
      // functions: ["Platzverwaltung & buchung","Mitgliedermanagement","Mannschaftsmanagement", "Punktspiele", "Training", "Veranstaltungen & Turniere", "Umfragen", "Vereinsblog"],
      functions: [],
      linkname: "zu den Funktionen",
      linkroute: FEATURES
    },
    {
      name: "Über uns",
      headline: "Software als Beruf und Tennis als Hobby",
      description: "MyClubPortal ist ein aufstrebendes Software-Startup, das sich auf die Entwicklung von Club Management Software spezialisiert hat. Gegründet im Jahr 2023 und in Braunschweig ansässig, bietet das Unternehmen innovative und maßgeschneiderte Lösungen für Clubs und Vereine aller Art. Durch das Netzwerk und die eigene Erfahrung im Tennisbereich ist die Grundlage gegeben, optimale Lösungen für den Tennisbereich zu erstellen",
      picture: ["https://images.pexels.com/photos/265125/pexels-photo-265125.jpeg"],
      functions: [],
      route: FEATURES
    },
    {
      name: "Unsere Kunden",
      headline: "Große Zufriedenheit und Zuspruch von unseren Kunden",
      description: "Wir sind stolz darauf, dass wir dem HTC Braunschweig als verlässlicher Partner zur Seite stehen und freuen uns auf weitere erfolgreiche Zusammenarbeit mit unseren Kunden. Unser Ziel ist es noch weitere Clubs und Vereine dabei zu unterstützen, die Prozesse zu optimieren und die Mitglieder zu begeistern. Kontaktiere uns, damit auch Dein Verein die Vorteile von MyClubPortal genießen kann.",
      picture: ["https://media.myclubportal.de/htclogo.png"],
      functions: [],
      route: FEATURES
    },
   /* {
      name: "Unsere Partner",
      headline: "Zusammen",
      description: "Als aufstrebendes Software-Startup sind wir ständig auf der Suche nach innovativen Lösungen und starken Partnern, um unser Angebot zu erweitern und unsere Kunden bestmöglich zu unterstützen. Derzeit haben wir noch keine Partnerschaften geschlossen, aber wir sind jederzeit offen für neue Kooperationen und freuen uns über Anfragen und Vorschläge.",
      picture: ["https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg"],
      functions: [],
      route: SUBSCRIPTIONS
    }
    */
  ]

  const updateAnalytics = (path) => {
    var promise = sendPageHit_api(path);
    promise.then()
      .catch();
  }


  useEffect(() => {
    setTimeout(() => setIndex((prevstate) => {
      if (prevstate >= images.length - 1) {
        return prevstate = 0;
      }
      else {
        return prevstate = prevstate + 1;

      }
    }), 10000)
    return () => {

    }
  }, [index])
  return (
    <>
      <Header></Header>
      <Box sx={{ position: "relative" }}>
        <CardMedia image={images[index]} sx={{ height: { xs: "250px", md: "300px", xl: "350px" }, transition: "2s", filter: "blur(5px)" }}></CardMedia>
        <Box sx={{ position: "absolute", color: "#fff", bottom: "10%", left: "10%", transform: "translate(0, 0)" }}>
          <Typography sx={{ typography: { xs: "h6", md: "h3" } }}>MyClubPortal </Typography>
          <Typography sx={{ typography: { xs: "body2", md: "h6" }, mt: { xs: "10px", md: "20px" } }}>Das webbasierte Portal für Tennisvereine</Typography>
          <Typography sx={{ typography: { xs: "body2", md: "body1" } }}>Online Tennisplatzbuchung und Vereinsmanagement</Typography>

          <Stack direction="row" spacing={3} sx={{ mt: "10px" }}>
            <Button variant="contained" color="white" sx={{}} startIcon={<StartSharp></StartSharp>} onClick={() => { window.open("https://demo.myclubportal.de", "_blank"); updateAnalytics("demo"); }}>Demo</Button>
            <Button variant="outlined" color="white" onClick={() => { window.open("mailto:contact@myclubportal.de"); updateAnalytics("contact"); }}>Kontakt</Button>
          </Stack>
        </Box>
      </Box>

      <Grid container direction={"column"} spacing={3}>
        <Grid item sx={{ backgroundColor: "#f9f9f9" }}>
          <Container maxWidth="xl" sx={{ px: { xs: 0, md: 8 } }}>
            <Grid item container direction={{ xs: "row", md: "row-reverse" }} justifyContent={{ xs: "center", md: "space-between" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={4}>
              <Grid item xs={10} md={6} lg={6}>
                <ImageCaroussel pictures={["https://media.myclubportal.de/Devices_croped.png"]}></ImageCaroussel>
              </Grid>
              <Grid item xs={10} md={6} lg={6}>
                <Typography variant="body1" color={"secondary"} textAlign={"left"} textTransform={"uppercase"}>Alles in einem Portal</Typography>
                <Typography variant="h5" gutterBottom={false} textAlign={"left"} >Digitalisiere den Verein für Mitglieder und Funktionäre</Typography>
                {/* <Divider></Divider> */}
                <Stack alignItems={"center"}>
                  <Typography color="text.secondary" sx={{ textAlign: "justify", pt: "20px", pb: "20px", typography: { xs: "body1", md: "body1" } }}>
                    Das digitale Vereinsportal ermöglicht ein vollumfängliches Management deines Vereins. Neben der Kernfunktion, online Tennisplätze zu buchen, ist es möglich Plätze, Mannschaften, Punktspiele, Trainings, Veranstaltungen und Turniere zu verwalten. Mithilfe von Umfragen und einem Blog können Mitglieder stets auf dem aktuellen Stand gehalten werden und sogar aktiv ihre Meinung einbringen. MyClubPortal wurde in Zusammenarbeit mit Vereinen und Spielern entwickelt, um den spezifischen Anforderungen der Tennisvereine gerecht zu werden.
                  </Typography>
                  <InternalLink linkName={"zu den Funktionen"} route={FEATURES}></InternalLink>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Container maxWidth="xl" sx={{ py: 2, px: { xs: 0, md: 8 } }}>
        <Grid container sx={{ pb: 2 }} direction={{ xs: "column", sm: "row" }} justifyContent={{ xs: "center", sm: "space-between" }} alignItems={{ xs: "center", sm: "flex-start" }} spacing={2}>
          <Grid item container justifyContent="center" xs={12} md={3}>
            <BenefitCard
              icon={<Devices color="primary" />}
              headline={"Zu jeder Zeit auf jedem Gerät"}
              subtext={"Buche Plätze egal ob Pc, Tablet, Handy,iOS, Android oder Windows... unsere responsive WebApp ist immer und auf jedem System einsatzbereit!"}
              width={{ xs: "70%", md: "100%" }}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} md={3}>
            <BenefitCard
              icon={<ManageAccounts color="primary" />}
              headline={"Smarte und einfache Planung"}
              subtext={"Vergiss Whatsapp und Doodle - Mit MyClubPortal hast du Teilnahmen, Aushilfeanfragen und Umfragen für Punktspiele, Turniere & Training auf einen Blick!"}
              width={{ xs: "70%", md: "100%" }}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} md={3}>
            <BenefitCard
              icon={<VolunteerActivismSharp color="primary" />}
              headline={"Hilfsbereit und offen"}
              subtext={"Unser vollintegriertes Supportsystem ermöglicht es uns unverzügliche Hilfe bei Problemen zu leisten. Neue Anforderungen nehmen wir dankend über den Feedback Service entgegen! "}
              width={{ xs: "70%", md: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>


      <Grid container direction={"column"} alignItems={"center"} spacing={3} sx={{ py: 4, backgroundColor: "#f9f9f9" }}>
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: 8 } }}>
          <Grid item>
            <Stack spacing={1}>
              {/* <Typography textAlign="center" variant="h5"><span style={{ color: "#1976d2" }}>kostenloses </span> Testportal <br /> oder <br /> Vereinsportal <span style={{ color: "#1976d2" }}>bestellen </span>?</Typography> */}
              <Typography textAlign="center" color="secondary" textTransform={"uppercase"} sx={{ typography: { xs: "h6", md: "h5" } }}>Überzeuge dich selbst...</Typography>
              <Typography textAlign="center" sx={{ typography: { xs: "body1", md: "h5" } }}>Fordere ein kostenloses Testsystem an</Typography>
              <Stack>


                <Stack direction="row" justifyContent={"center"} spacing={1}>
                  <Button size="small" variant="contained" color="secondary" sx={{ textTransform: "none" }} startIcon={<StartSharp></StartSharp>} onClick={() => { externalLink(); }}>Starte Demo</Button>
                  <Button size="small" variant="contained" color="primary" sx={{ textTransform: "none" }} startIcon={<Mail></Mail>} onClick={() => { navigate(CONTACT) }}>Antrag Testsystem</Button>
                </Stack>
                <InternalLink linkName={"zu den Angeboten"} route={SUBSCRIPTIONS}></InternalLink>
              </Stack>
            </Stack>
          </Grid>
        </Container>
      </Grid>

      <Grid container direction={"column"} spacing={3}>
        {features.map((feature, i) => (
          i > 0 &&
          <Feature feature={feature} align={(i + 1) % 2 != 0 && "left"} key={i}></Feature>
        ))
        }
      </Grid>
      <Footer></Footer>
    </>
  )
}

export default HomeContainer