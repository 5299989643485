import axios from "axios";

export const sendContactRequest_api = async (message,mail,firstname,lastname,intention) => {
    //fetch users data
    var data = {
        message: message,
        mail: mail,
        firstname: firstname,
        lastname: lastname,
        intention: intention
    }
    return await axios
        .post(process.env.REACT_APP_API_URL+"/contact",data)
};