import { CardMedia, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function HeaderTitleBox({ headline,imageUrl}) {
    return (
        <Box sx={{ position: "relative" }}>
            <CardMedia image={imageUrl} sx={{ height: { xs: "100px", md: "150px", xl: "200px" }, transition: "2s" }}></CardMedia>
            <Box sx={{ position: "absolute", color: "#fff", bottom: "10%", left: "10%", transform: "translate(0, 0)" }}>
                <Typography sx={{ typography: { xs: "h5", sm: "h4", md: "h3" } }}>{headline}</Typography>
            </Box>
        </Box>
    )
}

export default HeaderTitleBox