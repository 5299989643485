import { AppBar, Card, CardContent, Stack, Toolbar } from '@mui/material'
import React from 'react'
import { CREDITS, GDPR, IMPRINT } from '../navigation/CONSTANTS'
import InternalLink from './InternalLink'

function Footer() {
  return (
    <>
      {/* <Toolbar variant="dense"></Toolbar> */}
      {/* <AppBar elevation={0} position="fixed" sx={{ top: "auto", bottom: "0", borderTop: 1 }}>
        <Toolbar variant="dense" sx={{ justifyContent: { xs: "flex-end", md: "center" }, backgroundColor: "#fff" }}> */}
          <Card sx={{width: "100%", backgroundColor: "#eee"}}>
            <CardContent>
              <Stack direction="row" justifyContent={"space-between"} alignItems="center" sx={{ width: "100%" }}>
                <InternalLink route={GDPR} color="#999" linkName="Datenschutzerklärung"></InternalLink>
                <InternalLink route={IMPRINT} color="#999" linkName="Impressum"></InternalLink>
                <InternalLink route={CREDITS} color="#999" linkName="Credits"></InternalLink>
              </Stack>
            </CardContent>
          </Card>

        {/* </Toolbar>
      </AppBar> */}
    </>
  )
}

export default Footer