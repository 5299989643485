import {Grid } from '@mui/material'
import React from 'react'
import Feature from '../../components/Feature'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { SUBSCRIPTIONS } from '../../navigation/CONSTANTS'
import HeaderTitleBox from '../../components/HeaderTitleBox'

function FeatureContainer() {

    const features = [
        {
            name: "Tennisplatz",
            headline: "Online Tennisplätze buchen und verwalten",
            description: "Unsere Tennisplatzverwaltung ermöglicht es Clubs und Vereinen, die Tennisplätze und dessen Belegung zu verwalten. Mit MyClubPortal kannst Du schnell und einfach neue Plätze erstellen, diese für Wartungsarbeiten sperren oder für Abonoments und Veranstaltungen wie z.B. Punktspiele reservieren. Mithilfe von individuell definierbaren Regeln können die Tennisplätze jederzeit von Mitglieder oder Gästen online gebucht werden.",
            picture: ["https://media.myclubportal.de/Bookingtable.png","https://media.myclubportal.de/BookingDialog_PC.png","https://media.myclubportal.de/Courts_Overview_PC.png","https://media.myclubportal.de/Serien_Overview_PC.png","https://media.myclubportal.de/Serien_Dialog_PC.png","https://media.myclubportal.de/Booking_History_PC.png"],
            functions: ["Platzmanagement","individuelle Buchungsregeln","Gastbuchung","Serienbuchung","Buchungshistorie"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Mannschaften",
            headline: "Mannschaftsmanagement leicht und übersichtlich",
            description: "Unsere Funtkion rundherum von Mannschaften ist eine großartige Möglichkeit für Clubs und Vereine, die Tennismannschaften einfach und übersichtlich zu verwalten. Mit dieser Funktion kannst Du Mannschaften anlegen, einen Mannschaftsführer festlegen und Teammitglieder hinzufügen. Das System bietet auf einen Blick alle Informationen bzgl. Punktspiele, Tabellenstand und vieles mehr.",
            picture: ["https://media.myclubportal.de/Mannschaften.png","https://media.myclubportal.de/Team_TeamDetails_PC.png","https://media.myclubportal.de/Team_TeamDialogNuliga_PC.png","https://media.myclubportal.de/Team_TeamDialog_PC.png" ],
            functions: ["Verwaltung durch Mannschaftsführer & Admins","Übersicht Mannschaftsmitglieder","Saisonstatistiken","E-Mail Benachrichtigungen","Punktspiele"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Training",
            headline: "Wann trainiert wer mit wem?",
            description: "Unser Trainingsmanager-Feature ermöglicht es Tennisclubs und Vereinen, Trainings einfach und effektiv zu organisieren. Du kannst Trainingsgruppen erstellen und anschließend Trainings für diese Gruppen planen. Durch Zusagen der Trainingsgruppenmitglieder ist die Teilnhame für alle transparent und muss nicht länger z.B. über Whatsapp geklärt werden",
            picture: ["https://media.myclubportal.de/Training_GroupsExpanded_PC.png","https://media.myclubportal.de/Training_Overview_PC.png","https://media.myclubportal.de/Training_TrainingExpanded_PC.png"],
            functions: ["Erstellen von Trainingsgruppen", "Erstellen von Trainings", "Teilnehmerübersicht für Trainings"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Punktspiele",
            headline: "Punktspiele werden heutzutage digital geplant!",
            description: "Unser Punktspielfunktion ist die perfekte Lösung für Tennisclubs und -vereine, die Punktspiele einfach organisieren möchten. Mit dieser Funktion kannst Du Punktspiele für Deine Mannschaft erstellen und die Teilnahme Deiner Mannschaftskollegen planen, wodurch lästige Abfragen und WhatsApp-Gruppen überflüssig werden. Durch die Möglichkeit, Punktspiele über Nuliga automatisiert zu synchronisieren/erstellen, kann viel Zeit in der Verwaltung vermieden wären.",
            picture: ["https://media.myclubportal.de/PunktspielManager.png","https://media.myclubportal.de/Teammatch_overviewMenu.png","https://media.myclubportal.de/Teammatch_Participants_PC.png","https://media.myclubportal.de/Teammatch_Aushilfe_PC.png"],
            functions: ["Zu & Absagen von Punktspielen","Automatische Synchronisierung mit Nuliga","Integrierte Nuliga Tabellenstatistik", "Anfragen für Aushilfen", "Vereinsweite Livetickerüberischt"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Notifications & Emails",
            headline: "Bleib auf dem neusten Stand",
            description: "Unser Notifications & Email-Feature sorgt dafür, dass Du keine wichtigen Ereignisse oder Aktivitäten in deinem Tennisclub oder -verein verpasst. Mit dieser Funktion erhlst Du portalinterne Benachrichtigungen und/oder E-Mails zu allen relevanten Ereignissen wie Platzbuchungen, neuen Turnieren, neuen Punktspielen, Umfragen u.v.m. Durch die Wahlmöglichkeit, ob und wie Du Benachrichtigungen (Notification & E-Mail) erhalten möchtest, kannst Du Deine individuelle Portion an Information sicherstellen.",
            picture: ["https://media.myclubportal.de/Notifications.png"],
            functions: ["InApp Notifications für sämtliche Aktionen/Events", "Wahlweise zusätzliche E-Mail Benachrichtigungen", "Rundmails"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Veranstaltungen & Turniere",
            headline: "Plane zeitgerecht und effektiv",
            description: "Mit der Funktion für Events und Turniere kannst Du erfolgreiche Veranstaltungen organisieren und deine Mitglieder dazu motivieren, aktiv an Clubaktivitäten teilzunehmen. Du kannst Veranstaltungen erstellen und Mitglieder zur Teilnahme einladen. Durch die Möglichkeit, Teilnahmezusagen von Mitgliedern zu verwalten, wird die Planung von Events und Turnieren effektiver. Mit unserem Feature kannst DU den Überblick über die Teilnahme Deiner Mitglieder behalten und sicherstellen, dass alle wichtigen Informationen rechtzeitig kommuniziert werden. ",
            picture: ["https://media.myclubportal.de/Events_Overview_PC.png","https://media.myclubportal.de/Events_TournamentBoard_PC.png","https://media.myclubportal.de/Events_Dialog_PC.png" ],
            functions: ["Individuelle Turniere oder Veranstaltungen","Übersicht aller anstenden Events", "Zusagen von Teilnehmern","Turniere im KO-System mit Tunierboard"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Umfragen",
            headline: "Gib den Mitgliedern eine Stimme",
            description: "Unser Umfrage-Feature ermöglicht es Dir mit deinen Mitgliedern zu interagieren und deren Meinungen zu wichtigen Club-Themen zu erfahren. Du kannst gezielte Fragen an Deine Mitglieder stellen und Feedback erhalten, um Entscheidungen zu treffen, die den Bedürfnissen Deiner Mitglieder entsprechen.",
            picture: ["https://media.myclubportal.de/Umfragen.png", "https://media.myclubportal.de/Survey_Votes_PC.png", "https://media.myclubportal.de/Survey_Dialog_PC.png"],
            functions: ["Erstellen von vereinsweiten oder privaten Umfragen", "Individuelle konfigurierbare Umfragen", "Anonyme oder einsehbare Stimmen","Einzel oder mehrfache Stimmabgabe"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Blog",
            headline: "Ersetze langweilige Rundmails durch moderne Blogbeiträge",
            description: "Mit dem Blog-Feature kannst Du Beiträge zu verschiedenen Themen wie Tennis-Tipps, Club-Updates oder Ankündigungen verfassen und mit Deinen Mitgliedern teilen. Die Beiträge können durhc formatiertem Text und wahlweise durch Titelfotos erweitert werden. Mitglieder können mittels Kommentaren über die Beitrage diskutieren und ihre Meinung wiedergeben.",
            picture: ["https://media.myclubportal.de/Blog_Post_PC.png","https://media.myclubportal.de/Blog_PostDetailed_PC.png","https://media.myclubportal.de/Blog_Dialog_PC.png"],
            functions: ["Erstellen von Blogbeiträgen", "Beiträge individuell stylebar","Kommentarfunktion"],
            route: SUBSCRIPTIONS
        },
        {
            name: "Dashboards",
            headline: "Immer alles auf einen Blick!",
            description: "Die integrierten Dashboards sind eine perfekte Übersicht für Mitlgieder und Admins, um schnell alle wichtigen Informartionen zu sehen.",
            picture: ["https://media.myclubportal.de/Dashboard_User_PC.png", "https://media.myclubportal.de/Dashboard_Admin_PC.png"],
            functions: ["Profilüberischt", "Mannschaftsansicht","Anstehende Ereignisse", "Trainingsübersicht", "Letzter Beitrag", "AdminDashboard","Mitgliederstatistik","Aktivätenübersicht","Plätzestatus","Lizenzstatus"],
            route: SUBSCRIPTIONS
        }
    ]
    return (
        <>
            <Header />
            <HeaderTitleBox headline="Features" imageUrl="https://media.myclubportal.de/Background_Feature_Croped.jpg"/>
            <Grid container direction={"column"} spacing={3}>
                {features.map((feature, i) => (
                    <Feature feature={feature} align={i % 2 != 1 && "left"} key={i}></Feature>
                ))
                }
            </Grid>
            <Footer></Footer>

        </>
    )
}

export default FeatureContainer