import { Divider, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

function GDPRContainer() {

    return (
        <>
            <Header></Header>
            <Container sx={{ maxWidth: "xl", pt: 5, pb: 5 }}>
                <Stack spacing={3}>
                    <Typography variant="h5" align='center'>
                        Datenschutzerklärung
                    </Typography>
                    <Divider></Divider>
                    <Typography >
                        <p>Der Schutz Ihrer pers&ouml;nlichen Daten ist uns ein wichtiges Anliegen. Nachfolgend informieren wir Sie &uuml;ber die Verarbeitung Ihrer Daten bei der Nutzung unserer Internetseite, insbesondere im Zusammenhang mit dem Kontaktformular.</p>
                        <ol>
                            <li><strong>Verantwortliche Stelle</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Verantwortlich f&uuml;r die Verarbeitung Ihrer Daten im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:</p>
                        <p style={{ paddingLeft: "40px" }}>MyClubPortal</p>
                        <p style={{ paddingLeft: "40px" }}>Jan-Paul Brückmann</p>
                        <p style={{ paddingLeft: "40px" }}>Langer Kamp 2, Braunschweig</p>
                        <ol start="2">
                            <li><strong>Erfassung und Verarbeitung von personenbezogenen Daten</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>2.1.<strong> Besuch der Website</strong></p>
                        <p style={{ paddingLeft: "40px" }}>Beim Besuch unserer Website speichern wir keine Cookies auf Ihrem Endger&auml;t. Es werden keinerlei personenbezogene Daten automatisch erfasst.</p>
                        <p style={{ paddingLeft: "40px" }}>2.2. <strong>Kontaktformular</strong></p>
                        <p style={{ paddingLeft: "40px" }}>Wenn Sie unser Kontaktformular nutzen, um mit uns in Verbindung zu treten, werden die von Ihnen eingegebenen Daten (wie z.B. Name, E-Mail-Adresse, Nachricht) ausschlie&szlig;lich zum Zweck der Bearbeitung Ihrer Anfrage verwendet. Diese Daten werden nicht automatisch gespeichert, sondern dienen ausschlie&szlig;lich der Kommunikation mit Ihnen.</p>
                        <ol start="3">
                            <li><strong>Zweck der Datenverarbeitung</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Die Verarbeitung Ihrer Daten erfolgt ausschlie&szlig;lich zu den folgenden Zwecken:</p>
                        <ul>
                            <ul>
                                <li>Beantwortung von Anfragen, die &uuml;ber das Kontaktformular eingehen.</li>
                                <li>Kommunikation im Zusammenhang mit unseren Dienstleistungen.</li>
                            </ul>
                        </ul>
                        <ol start="4">
                            <li><strong>Weitergabe von Daten an Dritte</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Ihre personenbezogenen Daten werden grunds&auml;tzlich nicht an Dritte weitergegeben, es sei denn, dies ist zur Erf&uuml;llung unserer vertraglichen Pflichten erforderlich oder Sie haben ausdr&uuml;cklich eingewilligt.</p>
                        <ol start="5">
                            <li><strong>Speicherdauer</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Ihre Daten werden nur so lange gespeichert, wie es f&uuml;r die Erf&uuml;llung der Zwecke, f&uuml;r die sie erhoben wurden, erforderlich ist, oder solange dies gesetzlich vorgeschrieben ist.</p>
                        <ol start="6">
                            <li><strong>Ihre Rechte</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Sie haben das Recht auf Auskunft, Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Daten&uuml;bertragbarkeit und Widerspruch. Wenn Sie eines dieser Rechte aus&uuml;ben m&ouml;chten oder weitere Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, kontaktieren Sie uns bitte unter den oben angegebenen Kontaktdaten.</p>
                        <ol start="7">
                            <li><strong>Aktualisierung der Datenschutzerkl&auml;rung</strong></li>
                        </ol>
                        <p style={{ paddingLeft: "40px" }}>Diese Datenschutzerkl&auml;rung wird regelm&auml;&szlig;ig aktualisiert, um sicherzustellen, dass sie den aktuellen gesetzlichen Anforderungen entspricht. Die jeweils aktuelle Version finden Sie auf unserer Website.</p>
                        <p>Datum der letzten Aktualisierung: 01.01.2024</p>
                        <p>Vielen Dank f&uuml;r Ihr Vertrauen.</p>

                    </Typography>
                </Stack>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default GDPRContainer