import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ(props) {
  const { faq } = props;
  return (
    <>
      <Accordion sx={{borderLeft: 2, borderColor: "secondary.main"}}>
        <AccordionSummary sx={{ p: 2, backgroundColor: "#f9f9f9" }} expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}><Typography variant="body1">{faq.question}</Typography></AccordionSummary>
        <AccordionDetails sx={{ p: 2 }}><Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{faq.response}</Typography></AccordionDetails>
      </Accordion>
    </>
  )
}

export default FAQ