import { Menu } from '@mui/icons-material'
import { AppBar, Box, Button, IconButton, Stack, Toolbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';
import { CONTACT, FAQ, FEATURES, LOGIN, ROOT, SUBSCRIPTIONS, } from '../navigation/CONSTANTS';
import logo from "../assets/mcp_logo.png"
import { sendPageHit_api } from '../api/analytics';
import ScrollToTop from './ScrollToTop';


function Header() {

    const [open, setOpen] = useState();
    const location=useLocation();

    const updateVisits = () => {
        var promise = sendPageHit_api(location.pathname);
        promise.then()
          .catch();
      }

    const navigate = useNavigate();

    const handleClick = (route,external=false) => {
        if(external)
        {
          window.open(route,'_blank').focus();
        }
        else
        {
            navigate(route);
        }

    }

    useEffect(() => {
        updateVisits();
    }, [location]);
    return (
        <>
            <AppBar position="sticky" >
                <Toolbar variant="dense"  sx={{ justifyContent: "space-between", backgroundColor: "#fff" }} >
                    <Box component="img" sx={{ width: "150px" }} src={logo} />
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(ROOT) }}>Home</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(FEATURES) }}>Features</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(SUBSCRIPTIONS) }}>Preise</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(CONTACT) }}>Kontakt</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(FAQ) }}>FAQ</Button>
                            <Button variant="text" sx={{ fontWeight: "bold", color: "text.secondary" }} onClick={() => { handleClick(LOGIN,true) }}>Login</Button>
                        </Stack>
                    </Box>


                    <Box sx={{ display: { xs: "block", md: "none" }, color: "#000" }}>
                        <Stack direction="row" spacing={1}>
                            <IconButton onClick={() => setOpen(!open)}><Menu></Menu></IconButton>
                        </Stack>
                    </Box>

                </Toolbar>
            </AppBar >
            <ScrollToTop/>
            <DrawerMenu open={open} setOpen={setOpen}></DrawerMenu>
        </>
    )
}

export default Header