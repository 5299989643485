import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SubscritpionCard from '../../components/SubscritpionCard'
import subs from "../../mocks/subscritions.json"
import Headline from '../../components/Headline'
import HeaderTitleBox from '../../components/HeaderTitleBox'

function SubscriptionContainer() {

    return (
        <>
            <Header></Header>
            <HeaderTitleBox headline="Preise" imageUrl="https://media.myclubportal.de/Background_Feature_Croped.jpg"/>
            <Headline
            headline="Angebotspakete und Preise"
            subtitle="Wähle das passende Angebot für deinen Verein und zahle nur das Nötigste!"
            />
            <Container maxWidth={"xl"} sx={{pb: 5}}>
                <Grid container direction={"row"} justifyContent={"space-around"} spacing={3}  p={1}>
                    {subs.map((subscription, i) => (
                        <Grid item xs={11} sm={6} md={4} lg={3} key={i}>
                            <SubscritpionCard data={subscription}></SubscritpionCard>
                        </Grid>
                    ))
                    }
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

export default SubscriptionContainer