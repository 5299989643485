import { Box, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { Check } from '@mui/icons-material';
import ImageCaroussel from './ImageCaroussel';
import InternalLink from './InternalLink';

function Feature(props) {
    const { feature, align } = props;

    return (
        <>
            {align == "left" ?
                <Grid item sx={{ backgroundColor: "#f9f9f9" }}>
                    <Container maxWidth="xl" sx={{ px: { xs: 0, md: 8 } }}>
                        <Grid item container direction={{ xs: "row", md: "row-reverse" }} justifyContent={{ xs: "center", md: "space-between" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={1}>
                            <Grid item xs={10} md={5} lg={5}>
                                <Typography sx={{ display: { xs: "block", md: "none" } }} variant="body1" color={"secondary"} textAlign={"left"} textTransform={"uppercase"}>{feature.name}</Typography>
                                <Typography sx={{ display: { xs: "block", md: "none" }, mb: 2 }} variant="h5" gutterBottom={false} textAlign={"left"} >{feature.headline}</Typography>
                                <ImageCaroussel pictures={feature.picture}></ImageCaroussel>
                            </Grid>
                            <Grid item xs={10} md={5} lg={5}>
                                <Typography sx={{ display: { xs: "none", md: "block" } }} variant="body1" color={"secondary"} textAlign={"left"} textTransform={"uppercase"}>{feature.name}</Typography>
                                <Typography sx={{ display: { xs: "none", md: "block" } }} variant="h5" gutterBottom={false} textAlign={"left"} >{feature.headline}</Typography>
                                <Stack alignItems={"center"}>
                                    <Typography color="text.secondary" sx={{ textAlign: "justify", pt: "20px", pb: "20px", typography: { xs: "body1", md: "body1" } }}>
                                        {feature.description}
                                    </Typography>
                                    <List dense>
                                        {feature.functions.map((obj, i) => (

                                            <ListItem key={i}>
                                                <ListItemIcon><Check></Check></ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ color: "text.secondary" }}>{obj}</ListItemText>
                                            </ListItem>
                                        ))
                                        }
                                    </List>
                                    {feature.linkname && feature.linkroute ?
                                        <InternalLink linkName={feature.linkname} route={feature.linkroute}></InternalLink>
                                        :
                                        <></>

                                    }
                                </Stack>

                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                :
                <Grid item>
                    <Container maxWidth="xl" sx={{ px: { xs: 0, md: 8 } }}>
                        <Grid item container direction="row" justifyContent={{ xs: "center", md: "space-between" }} alignItems="center" sx={{ pb: 5, pt: 5 }} spacing={1}>
                            <Grid item xs={10} md={5} lg={5}>
                            <Typography sx={{ display: { xs: "block", md: "none" } }} variant="body1" color={"secondary"} textAlign={"left"} textTransform={"uppercase"}>{feature.name}</Typography>
                                <Typography sx={{ display: { xs: "block", md: "none" }, mb: 2 }} variant="h5" gutterBottom={false} textAlign={"left"} >{feature.headline}</Typography>
                                <ImageCaroussel pictures={feature.picture}></ImageCaroussel>
                            </Grid>
                            <Grid item xs={10} md={5} lg={5}>
                            <Typography sx={{ display: { xs: "none", md: "block" } }} variant="body1" color={"secondary"} textAlign={"left"} textTransform={"uppercase"}>{feature.name}</Typography>
                                <Typography sx={{ display: { xs: "none", md: "block" } }} variant="h5" gutterBottom={false} textAlign={"left"} >{feature.headline}</Typography>

                                <Stack alignItems={"center"}>
                                    <Typography color="text.secondary" sx={{ textAlign: "justify", pt: "20px", pb: "20px", typography: { xs: "body1", md: "body1" } }}>
                                        {feature.description}
                                    </Typography>
                                    <List dense>
                                        {feature.functions.map((obj, i) => (
                                            <ListItem key={i}>
                                                <ListItemIcon><Check></Check></ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ color: "text.secondary" }}>{obj}</ListItemText>
                                            </ListItem>
                                        ))
                                        }
                                    </List>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            }
        </>
    )
}

export default Feature